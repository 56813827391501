
import Forsiden, { getSSRData } from 'src/lib/pages/ForsidenV2';

//#region [Other]getServerSideProps
export const getServerSideProps = getSSRData("streamingguide_v2",
	{
		sgTopLogo: true,
        hasCustomBg: false,
        //customBgTargetAdId: "SponsorTrackingPixel",
        hasStickyBottomAd: true,
        pageTitle: "Filmweb Streamingguide - Filmweb",
        adSetup: "streamingguide"

	});
//#endregion




//#region [Component]
export default Forsiden;
//#endregion